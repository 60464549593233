.sticky-container {
  /*background-color: #333;*/
  padding: 0px;
  margin: 0px;
  position: fixed;
  right: -119px;
  top: 250px;
  width: 160px;
}

.sticky li {
  list-style-type: none;
  font-weight: bold;
  background-color: transparent;
  color: #ffffff;
  height: 50px;
  padding: 0px;
  margin: 0px 0px 10px 0px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  /* filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); */
}

.sticky li:hover {
  margin-left: -115px;
  /*-webkit-transform: translateX(-115px);
          -moz-transform: translateX(-115px);
          -o-transform: translateX(-115px);
          -ms-transform: translateX(-115px);
          transform:translateX(-115px);*/
  /*background-color: #8e44ad;*/
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
}

.sticky li img {
  float: left;
  margin: 5px 5px;
  margin-right: 10px;
}

.sticky li p {
  padding: 0px;
  margin: 0px;
  text-transform: uppercase;
  line-height: 43px;
}

/** content **/

h1 {
  font-weight: 900;
  font-size: 64px;
}

h2 {
  font-size: 26px;
}

p {
  line-height: 28px;
  font-size: 15px;
  padding-top: 50px;
}

p.credit {
  padding-top: 20px;
  font-size: 12px;
}

/** fork icon**/
.fork {
  position: absolute;
  top: 0px;
  left: 0px;
}
