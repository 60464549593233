.img-glow1 {
  filter: drop-shadow(0 0 10px rgba(255, 11, 11, 0.5)); /* Initial shadow */
  transition: filter 0.3s; /* Smooth transition */
  animation: glowAnimation1 2s infinite alternate;
  /* Animation for continuous glowing */
}

@keyframes glowAnimation1 {
  0% {
    filter: drop-shadow(0 0 10px rgba(211, 211, 223, 0.5)); /* Initial shadow */
  }
  100% {
    filter: drop-shadow(0 0 10px rgb(244, 240, 5)); /* Intensified shadow */
  }
}

.img-glow2 {
  filter: drop-shadow(0 0 10px rgba(255, 11, 11, 0.5)); /* Initial shadow */
  transition: filter 0.3s; /* Smooth transition */
  animation: glowAnimation2 2s infinite alternate; /* Animation for continuous glowing */
}

@keyframes glowAnimation2 {
  0% {
    filter: drop-shadow(0 0 10px rgba(211, 211, 223, 0.5)); /* Initial shadow */
  }
  100% {
    filter: drop-shadow(0 0 10px rgb(247, 56, 3)); /* Intensified shadow */
  }
}
.img-glow3 {
  filter: drop-shadow(0 0 10px rgba(255, 11, 11, 0.5)); /* Initial shadow */
  transition: filter 0.3s; /* Smooth transition */
  animation: glowAnimation3 2s infinite alternate; /* Animation for continuous glowing */
}

@keyframes glowAnimation3 {
  0% {
    filter: drop-shadow(0 0 10px rgba(2, 126, 43, 0.5)); /* Initial shadow */
  }
  100% {
    filter: drop-shadow(0 0 10px rgba(0, 0, 255, 1)); /* Intensified shadow */
  }
}
.img-glow4 {
  filter: drop-shadow(0 0 10px rgba(255, 11, 11, 0.5)); /* Initial shadow */
  transition: filter 0.3s; /* Smooth transition */
  animation: glowAnimation4 2s infinite alternate; /* Animation for continuous glowing */
}

@keyframes glowAnimation4 {
  0% {
    filter: drop-shadow(0 0 10px rgba(211, 211, 223, 0.5)); /* Initial shadow */
  }
  100% {
    filter: drop-shadow(0 0 10px rgb(231, 10, 235)); /* Intensified shadow */
  }
}
.img-glow5 {
  filter: drop-shadow(0 0 10px rgba(4, 255, 175, 0.5)); /* Initial shadow */
  transition: filter 0.3s; /* Smooth transition */
  animation: glowAnimation5 2s infinite alternate; /* Animation for continuous glowing */
}

@keyframes glowAnimation5 {
  0% {
    filter: drop-shadow(0 0 10px rgba(253, 253, 255, 0.5)); /* Initial shadow */
  }
  100% {
    filter: drop-shadow(0 0 10px rgb(0, 213, 255)); /* Intensified shadow */
  }
}
.img-glow1:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.img-glow2:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.img-glow3:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.img-glow4:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}
.img-glow5:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.fixed-image {
  float: right;
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
  object-fit: cover;
  border-radius: 50%; /* Ensure the image is round */
}

.profile {
  border-radius: 20%;
  object-fit: cover;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
